@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css");
/* @import url("https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css"); */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.AsideBackground{
   background-image: url(../public/assets/backgrounds/bg_side.png)
}

.MainBackground{
   background-image: url(../public/assets/backgrounds/bg_main.png)
}

.DashboardTopCardOneBackground{
   background-image: url(../public/assets/backgrounds/bg_main.png)
}

.DashboardTopCardIconTwoBackground{
   background-image: url(../public/assets/backgrounds/bg_main.png)
}

.DashboardTopCardIconOneBackground{
   background-image: url(../public/assets/backgrounds/bg_main.png)
}


/*builder*/
.react-form-builder{
  /* display:none; */
  /* display: flex  !important;
  flex-direction: row !important; */
  /* grid-template-columns: repeat(2, minmax(0, 1fr))!important;
  display: grid!important;
  background-color: rgb(60, 255, 0); */
}

.react-form-builder-preview{
  /* width: 300px!important; */

  /* width: 60px!important; */
}

.react-form-builder-toolbar{
  /* width: 300px!important; */
   
}

.active {
  text-decoration: underline;
  background-color: none;
  box-shadow: none;
 
}
.pagination li {
  margin: 1em;
}


